import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import {
  Flex,
  Heading,
  ThemeProvider,
  Paragraph,
  Link,
  Button,
} from "theme-ui";

import { BatchedWebSocketAugmentedWeb3Provider } from "@liquity/providers";
import { LiquityProvider } from "./hooks/LiquityContext";
import { WalletConnector } from "./components/WalletConnector";
import { TransactionProvider } from "./components/Transaction";
import { Icon } from "./components/Icon";
import { getConfig } from "./config";
import theme from "./theme";

import { DisposableWalletProvider } from "./testUtils/DisposableWalletProvider";
import { LiquityFrontend } from "./LiquityFrontend";
import { AppLoader } from "./components/AppLoader";

if (window.ethereum) {
  // Silence MetaMask warning in console
  Object.assign(window.ethereum, { autoRefreshOnNetworkChange: false });
}

if (process.env.REACT_APP_DEMO_MODE === "true") {
  const ethereum = new DisposableWalletProvider(
    process.env.REACT_APP_RPC_URL || `http://${window.location.hostname}:8545`,
    "0x4d5db4107d237df6a3d58ee5f70ae63d73d7658d4026f2eefd2f204c81682cb7"
  );

  Object.assign(window, { ethereum });
}

// Start pre-fetching the config
getConfig().then((config) => {
  // console.log("Frontend config:");
  // console.log(config);
  Object.assign(window, { config });
});

const EthersWeb3ReactProvider: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider
      getLibrary={(provider) =>
        new BatchedWebSocketAugmentedWeb3Provider(provider)
      }
    >
      {children}
    </Web3ReactProvider>
  );
};

const UnsupportedMainnetFallback: React.FC = () => (
  <Flex
    sx={{
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      textAlign: "center",
    }}
  >
    <Heading sx={{ mb: 3 }}>
      <Icon name="exclamation-triangle" /> This app is for testing purposes
      only.
    </Heading>

    {/* Shinto: Supported Chain text */}
    <Paragraph sx={{ mb: 3 }}>
      Please change your network to PulseChain.
    </Paragraph>

    <Paragraph>
      If you'd like to use the FLEX Protocol on mainnet, please pick a frontend{" "}
      <Link href="#">
        here <Icon name="external-link-alt" size="xs" />
      </Link>
      .
    </Paragraph>
  </Flex>
);

export const ChangeNetwork = async (connector: any) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await (window as any).ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x171" }],
    });
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await (window as any).ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x171",
              chainName: "PulseChain",
              nativeCurrency: {
                name: "PLS",
                symbol: "PLS",
                decimals: 18,
              },
              rpcUrls: ["https://rpc.pulsechain.com/"],
              blockExplorerUrls: ["https://scan.pulsechain.com"],
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};

const App = () => {
  const unsupportedNetworkFallback = (chainId: number) => (
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      {/* Shinto: Supported Chain text */}
      <Heading sx={{ mb: 3 }}>
        <Icon name="exclamation-triangle" /> FLEX Protocol is not yet deployed
        to {chainId === 369 ? "mainnet" : "this network"}.
      </Heading>
      Please switch to PulseChain.
      {/* <Paragraph>Please switch to</Paragraph> */}
      <Button
        variant="connect_wallet"
        sx={{ p: "0.5em 1em", mt: 2 }}
        onClick={ChangeNetwork}
      >
        Switch Network
      </Button>
    </Flex>
  );

  return (
    <EthersWeb3ReactProvider>
      <ThemeProvider theme={theme}>
        <WalletConnector loader={<AppLoader />}>
          <LiquityProvider
            loader={<AppLoader />}
            unsupportedNetworkFallback={unsupportedNetworkFallback}
            unsupportedMainnetFallback={<UnsupportedMainnetFallback />}
          >
            <TransactionProvider>
              <LiquityFrontend loader={<AppLoader />} />
            </TransactionProvider>
          </LiquityProvider>
        </WalletConnector>
      </ThemeProvider>
    </EthersWeb3ReactProvider>
  );
};

export default App;
