import React from "react";
import { Flex } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

const selector = ({ remainingLiquidityMiningLQTYReward2 }: LiquityStoreState) => ({
  remainingLiquidityMiningLQTYReward2
});

export const RemainingLQTY: React.FC = () => {
  const { remainingLiquidityMiningLQTYReward2 } = useLiquitySelector(selector);

  return (
    <Flex sx={{ mr: 2, fontSize: 2, fontWeight: "medium" }}>
      {remainingLiquidityMiningLQTYReward2.prettify(0)} FLEX remaining
    </Flex>
  );
};