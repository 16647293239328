import { Container } from "theme-ui";
import { SystemStats } from "../components/SystemStats";
import { Farm as FarmPanel } from "../components/Farm/Farm";
import { FarmSecond } from "../components/Farmlp/FarmSecond";
import { Farm as FarmThird } from "../components/Farm3/Farm";

export const Farm: React.FC = () => (
  <Container variant="columns" sx={{ justifyContent: "flex-start" }}>
    <Container variant="left">
      <FarmPanel />
      <FarmSecond />
      <FarmThird />
    </Container>

    <Container variant="right">
      <SystemStats />
    </Container>
  </Container>
);