import React, { useEffect, useState } from "react";
import { Button, Box, Flex, Card, Heading, Paragraph } from "theme-ui";

import {
  Decimal,
  Percent,
  LiquityStoreState,
  MINIMUM_COLLATERAL_RATIO,
} from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN } from "../../strings";

import { Icon } from "../Icon";
import { LoadingOverlay } from "../LoadingOverlay";
import { EditableRow, StaticRow } from "../Trove/Editor";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescription";
import { useMyTransactionState } from "../Transaction";

import { DistributionAction } from "./DistributionAction";
import { InfoIcon } from "../InfoIcon";
import { InfoMessage } from "../InfoMessage";

const mcrPercent = new Percent(MINIMUM_COLLATERAL_RATIO).toString(0);

const select = ({ price, fees, total, lusdBalance, sacrificeDetails }: LiquityStoreState) => ({
  price,
  fees,
  total,
  lusdBalance,
  sacrificeDetails
});

const transactionId = "distribution";

export const DistributionManager: React.FC = () => {
  const { sacrificeDetails } = useLiquitySelector(select);
  const [changePending, setChangePending] = useState(false);

  // const [lusdAmount, setLUSDAmount] = useState(Decimal.ZERO);
  // const editingState = useState<string>();
  // const dirty = !lusdAmount.isZero;
  // const ethAmount = lusdAmount.div(price);
  // const redemptionRate = fees.redemptionRate(lusdAmount.div(total.debt));
  // const feePct = new Percent(redemptionRate);
  // const ethFee = ethAmount.mul(redemptionRate);
  // const maxRedemptionRate = redemptionRate.add(0.001); // TODO slippage tolerance
  // const myTransactionState = useMyTransactionState(transactionId);

  const timeDiff = sacrificeDetails[0][0] ? sacrificeDetails[0][0]/(60*60*24) : sacrificeDetails[0][0];
  const claimableEARN = (sacrificeDetails[0][1]/1e8).toFixed(4);
  const eligibleEARN = sacrificeDetails[0][2]/1e8;
  const canRedeem = sacrificeDetails[0][1] ? true : false;

  // const vestTimeDiff = sacrificeDetails[1][0] ? sacrificeDetails[1][0]/(60*60*12) : sacrificeDetails[1][0];
  // const vestClaimableEARN = (sacrificeDetails[1][1]/1e18).toFixed(4);
  // const vestEligibleEARN = sacrificeDetails[1][2]/1e18;
  // const vestCanRedeem = sacrificeDetails[1][1] ? true : false;

  return (
    <>
    {
      eligibleEARN ? 
      <Card>
        <Heading>
          CLAIM
        </Heading>

        <Box sx={{ p: [2, 3] }}>
          <StaticRow
            label="Distribution Days"
            inputId="distribution-days"
            amount={ `${parseInt(timeDiff)}` }
            // pendingAmount={feePct.toString(2)}
            unit="/ 180 Days"
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ minWidth: "240px" }}>
                    Number of days that have passed since the emission started.
                  </Card>
                }
              />
            }
          />

          <StaticRow
            label="Claimable FLEX"
            inputId="claimable-flex"
            amount={ `${claimableEARN}` }
            // pendingAmount={feePct.toString(2)}
            unit={` FLEX`}
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ minWidth: "240px" }}>
                    Total FLEX token accumulated since your last claim.
                  </Card>
                }
              />
            }
          />

          <StaticRow
            label="Total Eligible FLEX"
            inputId="eligible-flex"
            amount={ `${Number(eligibleEARN) }`}
            // pendingAmount={feePct.toString(2)}
            unit={`FLEX`}
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ minWidth: "240px" }}>
                    Your total share of FLEX Token.
                  </Card>
                }
              />
            }
          />

          {
            <ActionDescription>
              You are eligible to claim { `${claimableEARN}` } FLEX Token.
            </ActionDescription>
          }

          <Flex variant="layout.actions">
            <DistributionAction
              transactionId={transactionId}
              disabled={!canRedeem}
              type={"sac"}
            />
          </Flex>
        </Box>

        {changePending && <LoadingOverlay />}
      </Card>
      : <>
      <Card>
          <Box sx={{ p: [2, 3] }}>
            <InfoMessage title="Alert">
              <Paragraph>
                You are not eligible to claim community distribution.
              </Paragraph>
            </InfoMessage>
          </Box>
        </Card>
      </>
    }

    {/* {
      vestEligibleEARN ? 
      <Card>
        <Heading>
          Team CLAIM
        </Heading>

        <Box sx={{ p: [2, 3] }}>
          <StaticRow
            label="Distribution Days"
            inputId="distribution-days"
            amount={ `${parseInt(vestTimeDiff)}` }
            // pendingAmount={feePct.toString(2)}
            unit="/ 180 Days"
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ minWidth: "240px" }}>
                    Number of days that have passed since the emission started.
                  </Card>
                }
              />
            }
          />

          <StaticRow
            label="Claimable EARN"
            inputId="claimable-earn"
            amount={ `${vestClaimableEARN}` }
            // pendingAmount={feePct.toString(2)}
            unit={` EARN`}
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ minWidth: "240px" }}>
                    Total EARN token accumulated since your last claim.
                  </Card>
                }
              />
            }
          />

          <StaticRow
            label="Total Eligible EARN"
            inputId="eligible-earn"
            amount={ `${vestEligibleEARN.toFixed(0)}` }
            // pendingAmount={feePct.toString(2)}
            unit={`EARN`}
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ minWidth: "240px" }}>
                    Your total share of EARN Token.
                  </Card>
                }
              />
            }
          />

          {
            <ActionDescription>
              You are eligible to claim { `${vestClaimableEARN}` } EARN Token.
            </ActionDescription>
          }

          <Flex variant="layout.actions">
            <DistributionAction
              transactionId={transactionId}
              disabled={!vestCanRedeem}
              type={"pct"}
            />
          </Flex>
        </Box>

        {changePending && <LoadingOverlay />}
      </Card>
      : <></>
    } */}

    </>
  );
};