import React, { useState, useCallback, useEffect, useRef } from "react";
import { LiquityStoreState, Decimal } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { FarmViewContext3 } from "./FarmViewContext";
import { transitions } from "./transitions";
import type { FarmView, FarmEvent } from "./transitions";

const transition = (view: FarmView, event: FarmEvent): FarmView => {
  const nextView = transitions[view][event] ?? view;
  return nextView;
};

const getInitialView = (
  liquidityMiningStake3: Decimal,
  remainingLiquidityMiningLQTYReward3: Decimal,
  liquidityMiningLQTYReward3: Decimal
): FarmView => {
  if (remainingLiquidityMiningLQTYReward3.isZero) return "DISABLED";
  if (liquidityMiningStake3.isZero && liquidityMiningLQTYReward3.isZero) return "INACTIVE";
  return "ACTIVE";
};

const selector = ({
  liquidityMiningStake3,
  remainingLiquidityMiningLQTYReward3,
  liquidityMiningLQTYReward3
}: LiquityStoreState) => ({
  liquidityMiningStake3,
  remainingLiquidityMiningLQTYReward3,
  liquidityMiningLQTYReward3
});

export const FarmViewProvider3: React.FC = props => {
  const { children } = props;
  const {
    liquidityMiningStake3,
    remainingLiquidityMiningLQTYReward3,
    liquidityMiningLQTYReward3
  } = useLiquitySelector(selector);

  const [view, setView] = useState<FarmView>(
    getInitialView(
      liquidityMiningStake3,
      remainingLiquidityMiningLQTYReward3,
      liquidityMiningLQTYReward3
    )
  );
  const viewRef = useRef<FarmView>(view);

  const dispatchEvent = useCallback((event: FarmEvent) => {
    const nextView = transition(viewRef.current, event);

    //Shinto
    // console.log(
    //   "dispatchEvent() [current-view, event, next-view]",
    //   viewRef.current,
    //   event,
    //   nextView
    // );
    setView(nextView);
  }, []);

  useEffect(() => {
    viewRef.current = view;
  }, [view]);

  useEffect(() => {
    if (liquidityMiningStake3.isZero && liquidityMiningLQTYReward3.isZero) {
      dispatchEvent("UNSTAKE_AND_CLAIM_CONFIRMED2");
    } else if (liquidityMiningStake3.isZero && !liquidityMiningLQTYReward3.isZero) {
      dispatchEvent("UNSTAKE_CONFIRMED2");
    }
  }, [liquidityMiningStake3.isZero, liquidityMiningLQTYReward3.isZero, dispatchEvent]);

  const provider = {
    view,
    dispatchEvent
  };

  return <FarmViewContext3.Provider value={provider}>{children}</FarmViewContext3.Provider>;
};