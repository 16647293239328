export type Lexicon = {
  term: string;
  description?: string;
  link?: string;
};

export const BORROW_FEE: Lexicon = {
  term: "Borrowing Fee",
  description:
    "The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in HEXDC) and is part of a Vault's debt. The fee varies between 0.5% and 5% depending on HEXDC redemption volumes.",
};

export const TVL: Lexicon = {
  term: "TVL",
  description:
    "The Total Value Locked (TVL) is the total value of HEX Token locked as collateral in the system, given in HEX and USD.",
};

export const STAKED_LQTY: Lexicon = {
  term: "Staked FLEX",
  description:
    "The total amount of FLEX that is staked for earning fee revenue.",
};

export const TCR: Lexicon = {
  term: "Total Collateral Ratio",
  description:
    "The ratio of the Dollar value of the entire system collateral at the current HEX:USD price, to the entire system debt.",
};

export const RECOVERY_MODE: Lexicon = {
  term: "Recovery Mode",
  description:
    "Recovery Mode is activated when the Total Collateral Ratio (TCR) falls below 150%. When active, your Vault can be liquidated if its collateral ratio is below the TCR. The maximum collateral you can lose from liquidation is capped at 110% of your Vault's debt. Operations are also restricted that would negatively impact the TCR.",
};

export const STABILITY_POOL_LUSD: Lexicon = {
  term: "HEXDC in Stability Pool",
  description:
    "The total HEXDC currently held in the Stability Pool, expressed as an amount and a fraction of the HEXDC supply.",
};

export const KICKBACK_RATE: Lexicon = {
  term: "Kickback Rate",
  description:
    "A rate between 0 and 100% set by the Frontend Operator that determines the fraction of FLEX that will be paid out as a kickback to the Stability Providers using the frontend.",
};

export const ETH: Lexicon = {
  term: "HEX",
};

export const LUSD: Lexicon = {
  term: "HEXDC",
};

export const LQTY: Lexicon = {
  term: "FLEX",
};

//
export const ALLOWANCE: Lexicon = {
  term: "HEX Allowance",
  description: "HEX Allowance",
};

//Shinto: Distribution
export const LIQUIDIDATION_NORMAL: Lexicon = {
  term: "Liquidation Price (Normal Mode)",
  description: "The USD value at which your Vault will drop below 110% Collateral Ratio and be at risk of liquidation. You should manage your position to de avoid liquidation by monitoring normal mode liquidation price",
};

export const LIQUIDIDATION_RECOVERY: Lexicon = {
  term: "Liquidation Price (Recovery Mode)",
  description: "The USD value at which your Vault will drop below 150% Collateral Ratio and be at risk of liquidation during Recovery Mode. You should be manage your position to avoid liquidation by monitoring the recovery mode liquidation price.",
};

export const DISTRIBUTION: Lexicon = {
  term: "Claim Your FLEX Token",
  description: "Claim Your FLEX Token",
};

export const TROVES: Lexicon = {
  term: "Vaults",
  description: "The total number of active Vaults in the system.",
};

export const LUSD_SUPPLY: Lexicon = {
  term: "HEXDC supply",
  description: "The total HEXDC minted by the FLEX Protocol.",
};

export const MPPLSX: Lexicon = {
  term: "Market Price",
  description: "The current HEX Market Price",
};

export const MPPXDC: Lexicon = {
  term: "Market Price",
  description: "The current HEXDC Market Price",
};

export const MPEARN: Lexicon = {
  term: "Market Price",
  description: "The current FLEX Market Price",
};

export const OPEARN: Lexicon = {
  term: "Oracle Price",
  description: "The current HEX Oracle Price",
};
