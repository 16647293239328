import axios from "axios"

export const getTokensPrice = async () => {
  try {
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=pulsex,tether-pulsechain,hex"
    );

    const tokens = response.data.reduce((result: any, token: any) => {
      result[token.symbol] = token.current_price;
      return result;
    }, {});

    return tokens;
  } catch (error) {
    throw new Error("Failed to retrieve price from Coingecko");
  }
};

export const getEarnPrice = async () => {
  try {
    const response = await axios.get(
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0x476d63aB94B4E86614Df0C3D5A27E9e22631D062,0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65,0x9756F095DfA27D4c2EAE0937a7b8a6603D99Affb,0xed77CbbB80e5a5C3A1FE664419d6F690766b5913,0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9"
    );

    const tokens = response.data.pairs.reduce((result: any, token: any) => {
      result[token.baseToken.symbol] = token.priceUsd;
      return result;
    }, {});

    return tokens;

  } catch (error) {
    throw new Error("Failed to retrieve price from Dex Screeener");
  }
};