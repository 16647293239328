import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Link, Paragraph } from "theme-ui";
import { useLiquity } from "../../../../hooks/LiquityContext";
import { Icon } from "../../../Icon";
import { InfoMessage } from "../../../InfoMessage";
import { useFarmView3 } from "../../context/FarmViewContext";
import { RemainingLQTY } from "../RemainingLQTY";
import { Yield } from "../Yield";

const uniLink = (token0: string, token1: string) => `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/add/V2/${token0}/${token1}`;

export const Inactive: React.FC = () => {
  const { dispatchEvent } = useFarmView3();

  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  
  addresses["earnToken"] = "0xb513038BbFdF9D40B676F41606f4F61D4b02c4A2";
  addresses["plsxToken"] = "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab";

  const handleStakePressed = useCallback(() => {
    dispatchEvent("STAKE_PRESSED2");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        PLSX-EARN LP Staking
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLQTY />
        </Flex>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="You aren't farming FLEX.">
          <Paragraph>You can farm FLEX by staking your PulseX PLSX-EARN LP tokens.</Paragraph>

          <Paragraph sx={{ mt: 2 }}>
            You can obtain LP tokens by adding liquidity to the{" "}
            <Link href={uniLink(addresses["plsxToken"], addresses["earnToken"])} target="_blank">
              PLSX-EARN pool on PulseX. <Icon name="external-link-alt" size="xs" />
            </Link>
          </Paragraph>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", flex: 1 }}>
            <Yield />
          </Flex>
          <Button onClick={handleStakePressed}>Stake</Button>
        </Flex>
      </Box>
    </Card>
  );
};