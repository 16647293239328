import React, { useCallback, useState } from "react";
import { Heading, Box, Flex, Card, Button } from "theme-ui";
import { Decimal, Difference, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { LP, GT } from "../../../../strings";
import { Icon } from "../../../Icon";
import { EditableRow, StaticRow } from "../../../Trove/Editor";
import { LoadingOverlay } from "../../../LoadingOverlay";
import { useFarmView3 } from "../../context/FarmViewContext";
import { useMyTransactionState } from "../../../Transaction";
import { Confirm } from "../Confirm";
import { Description } from "../Description";
import { Approve } from "../Approve";
import { Validation } from "../Validation";

const selector = ({
  liquidityMiningStake3,
  liquidityMiningLQTYReward3,
  uniTokenBalance3,
  totalStakedUniTokens3,
}: LiquityStoreState) => ({
  liquidityMiningStake3,
  liquidityMiningLQTYReward3,
  uniTokenBalance3,
  totalStakedUniTokens3,
});

const transactionId = /farm3-/;

export const Adjusting: React.FC = () => {
  const { dispatchEvent } = useFarmView3();
  let {
    liquidityMiningStake3,
    liquidityMiningLQTYReward3,
    uniTokenBalance3,
    totalStakedUniTokens3,
  } = useLiquitySelector(selector);
  liquidityMiningStake3 = liquidityMiningStake3.div(1e10);
  uniTokenBalance3 = uniTokenBalance3.div(1e10);
  totalStakedUniTokens3 = totalStakedUniTokens3.div(1e10);

  const [amount, setAmount] = useState<Decimal>(liquidityMiningStake3);
  const editingState = useState<string>();

  const transactionState = useMyTransactionState(transactionId);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";
  const isDirty = !amount.eq(liquidityMiningStake3);
  const maximumAmount = liquidityMiningStake3.add(uniTokenBalance3);
  const hasSetMaximumAmount = amount.eq(maximumAmount);

  console.log("🚀 ~ liquidityMiningStake3, uniTokenBalance3, totalStakedUniTokens3, isDirty, amount:", liquidityMiningStake3, uniTokenBalance3, totalStakedUniTokens3, isDirty, amount, amount.mul(1e10))

  const handleCancelPressed = useCallback(() => {
    dispatchEvent("CANCEL_PRESSED2");
  }, [dispatchEvent]);

  const nextTotalStakedUniTokens = isDirty
    ? totalStakedUniTokens3.sub(liquidityMiningStake3).add(amount)
    : totalStakedUniTokens3;

  const originalPoolShare = liquidityMiningStake3.mulDiv(
    100,
    totalStakedUniTokens3
  );
  const poolShare = amount.mulDiv(100, nextTotalStakedUniTokens);

  const poolShareChange =
    liquidityMiningStake3.nonZero &&
    Difference.between(poolShare, originalPoolShare).nonZero;

  return (
    <Card>
      <Heading>
        PLSX-EARN LP Staking
        {isDirty && !isTransactionPending && (
          <Button
            variant="titleIcon"
            sx={{ ":enabled:hover": { color: "danger" } }}
            onClick={() => setAmount(liquidityMiningStake3)}
          >
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Heading>

      <Box sx={{ p: [2, 3] }}>
        <EditableRow
          label="Stake LP"
          inputId="farm-stake-amount"
          amount={
            isDirty ? amount.prettify(4) : liquidityMiningStake3.prettify(4)
          }
          unit={LP}
          editingState={editingState}
          editedAmount={amount.toString(4)}
          setEditedAmount={(amount) => setAmount(Decimal.from(amount))}
          maxAmount={maximumAmount.toString()}
          maxedOut={hasSetMaximumAmount}
          usdval="0"
        ></EditableRow>

        {poolShare.infinite ? (
          <StaticRow label="Pool share" inputId="farm-share" amount="N/A" />
        ) : (
          <StaticRow
            label="Pool share"
            inputId="farm-share"
            amount={poolShare.prettify(4)}
            unit="%"
            pendingAmount={poolShareChange?.prettify(4).concat("%")}
            pendingColor={poolShareChange?.positive ? "success" : "danger"}
          />
        )}

        <StaticRow
          label="Reward"
          inputId="farm-reward-amount"
          amount={liquidityMiningLQTYReward3.prettify(4)}
          color={liquidityMiningLQTYReward3.nonZero && "success"}
          unit={GT}
        />

        {isDirty && <Validation amount={amount.mul(1e10)} />}
        {isDirty && <Description amount={amount.mul(1e10)} />}

        <Flex variant="layout.actions">
          <Button variant="cancel" onClick={handleCancelPressed}>
            Cancel
          </Button>
          <Approve amount={amount.mul(1e10)} />
          <Confirm amount={amount.mul(1e10)} />
        </Flex>
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
