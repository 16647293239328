import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useStabilityView } from "./context/StabilityViewContext";
import { RemainingLQTY } from "./RemainingLQTY";
import { Yield } from "./Yield";
import { Icon } from "../Icon";

export const NoDeposit: React.FC = (props) => {
  const { dispatchEvent } = useStabilityView();

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("DEPOSIT_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        <div>
          <Icon
            style={{ marginRight: 10, color: "#fb05f1" }}
            name="swimming-pool"
          />
          Stability Pool
        </div>
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLQTY />
        </Flex>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="You have no HEXDC in the Stability Pool.">
          You can earn HEX and FLEX rewards by depositing HEXDC.
        </InfoMessage>

        <Flex variant="layout.actions">
          <Flex
            sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center" }}
          >
            <Yield />
          </Flex>
          <Button onClick={handleOpenTrove}>Deposit</Button>
        </Flex>
      </Box>
    </Card>
  );
};
