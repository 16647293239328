import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

const selector = ({
  uniTokenBalance3,
  uniTokenAllowance3,
  liquidityMiningStake3
}: LiquityStoreState) => ({
  uniTokenBalance3,
  uniTokenAllowance3,
  liquidityMiningStake3
});

type FarmStakeValidation = {
  isValid: boolean;
  hasApproved: boolean;
  hasEnoughUniToken: boolean;
  isWithdrawing: boolean;
  amountChanged: Decimal;
  maximumStake: Decimal;
  hasSetMaximumStake: boolean;
};

export const useValidationState2 = (amount: Decimal): FarmStakeValidation => {
  let { uniTokenBalance3, uniTokenAllowance3, liquidityMiningStake3 } = useLiquitySelector(selector);
  uniTokenBalance3 = uniTokenBalance3.div(1e10)
  uniTokenAllowance3 = uniTokenAllowance3.div(1e10)
  liquidityMiningStake3 = liquidityMiningStake3.div(1e10)
  amount = amount.div(1e10)

  const isWithdrawing = liquidityMiningStake3.gt(amount);
  const amountChanged = isWithdrawing
    ? liquidityMiningStake3.sub(amount)
    : Decimal.from(amount).sub(liquidityMiningStake3);
  const maximumStake = liquidityMiningStake3.add(uniTokenBalance3);
  const hasSetMaximumStake = amount.eq(maximumStake);

  if (isWithdrawing) {
    return {
      isValid: true,
      hasApproved: true,
      hasEnoughUniToken: true,
      isWithdrawing,
      amountChanged,
      maximumStake,
      hasSetMaximumStake
    };
  }

  const hasApproved = !uniTokenAllowance3.isZero && uniTokenAllowance3.gte(amountChanged);
  const hasEnoughUniToken = !uniTokenBalance3.isZero && uniTokenBalance3.gte(amountChanged);

  return {
    isValid: hasApproved && hasEnoughUniToken,
    hasApproved,
    hasEnoughUniToken,
    isWithdrawing,
    amountChanged,
    maximumStake,
    hasSetMaximumStake
  };
};