import React from "react";
import {
  Card,
  Heading,
  Box,
  Flex,
  Button,
  Link,
  Paragraph,
  Label,
  Image,
} from "theme-ui";
import { useLiquity } from "../../hooks/LiquityContext";
import { Icon } from "../../components/Icon";
import { toast } from "react-toastify";
import HEXicon from "../../assets/icons/hex.svg";
import FLEXicon from "../../assets/icons/flex.svg";
import EARNicon from "../../assets/icons/EARNtoken.svg";
import HEXDCIcon from "../../assets/icons/hexdc.svg";
import PLSXIcon from "../../assets/icons/pulsex.svg";

// const portalXLink = (tokenAddress: string) => `https://portalxswap.io/?fromChain=PULSECHAIN&toChain=PULSECHAIN&from=0x95B303987A60C71504D99Aa1b13B4DA07b0790ab&to=${tokenAddress}`;
const piteasLink = (tokenAddress: string) =>
  `https://app.piteas.io/#/swap?inputCurrency=PLS&outputCurrency=${tokenAddress}`;
const portalXLink = (tokenAddress: string) =>
  `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=${tokenAddress}`;
const pulseXLink = (token0: string, token1: string) =>
  `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/add/V2/${token0}/${token1}`;

// add token to wallet
const addTokenToWallet = (
  tokenSymbol: string,
  tokenAddress: string,
  tokenImage: string
) => async () => {
  if (window.ethereum) {
    try {
      const { ethereum } = window as any;
      let url = "";

      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        url = `http://localhost:3000/`;
      } else {
        url = `https://www.flex.powercity.io/`;
      }

      await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: 8,
            image: url + tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const TradingPair: React.FC = () => {
  const {
    liquity: {
      connection: { addresses },
    },
  } = useLiquity();

  addresses["earnToken"] = "0xb513038BbFdF9D40B676F41606f4F61D4b02c4A2";
  addresses["plsxToken"] = "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab";
  
  const copyClipboard = (currency: any, address: any) => {
    navigator.clipboard.writeText(address);
    toast.success(`${currency} Address Copied!`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <>
      <Card>
        <Heading>
          Trading Pair
          <Flex sx={{ justifyContent: "flex-end" }}>
            {/* <RemainingLQTY /> */}
          </Flex>
        </Heading>
        <Box sx={{ p: [2, 3] }}>
          <Paragraph>
            Use the below buttons to buy $FLEX & add liquidity on PulseX.
          </Paragraph>

          <Box sx={{ p: [1, 2] }}>
            <Flex
              sx={{
                alignItems: "stretch",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={HEXDCIcon}
                  variant="avatar"
                />
                Buy HEXDC
              </Flex>

              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Link
                  href={piteasLink(addresses["lusdToken"])}
                  target="_blank"
                  paddingRight={12}
                >
                  Buy on Piteas <Icon name="external-link-alt" size="xs" />
                </Link>
                <Link
                  href={portalXLink(addresses["lusdToken"])}
                  target="_blank"
                >
                  Buy on PulseX <Icon name="external-link-alt" size="xs" />
                </Link>
              </Flex>
            </Flex>

            <Flex
              sx={{
                alignItems: "stretch",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={FLEXicon}
                  variant="avatar"
                />{" "}
                Buy FLEX
              </Flex>
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Link
                  href={piteasLink(addresses["lqtyToken"])}
                  target="_blank"
                  paddingRight={12}
                >
                  Buy on Piteas <Icon name="external-link-alt" size="xs" />
                </Link>
                <Link
                  href={portalXLink(addresses["lqtyToken"])}
                  target="_blank"
                >
                  Buy on PulseX <Icon name="external-link-alt" size="xs" />
                </Link>
              </Flex>
            </Flex>

            <Flex
              sx={{
                alignItems: "stretch",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer" }}
                  src={HEXDCIcon}
                  variant="avatar"
                />
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={HEXicon}
                  variant="avatar"
                />
                Add HEXDC-HEX Pair
              </Flex>
              <Link
                href={pulseXLink(addresses["plxToken"], addresses["lusdToken"])}
                target="_blank"
              >
                Add on PulseX <Icon name="external-link-alt" size="xs" />
              </Link>
              {/* <Button>Buy</Button> */}
            </Flex>

            <Flex
              sx={{
                alignItems: "stretch",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer" }}
                  src={FLEXicon}
                  variant="avatar"
                />
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={HEXicon}
                  variant="avatar"
                />
                Add FLEX-HEX Pair
              </Flex>
              <Link
                href={pulseXLink(addresses["plxToken"], addresses["lqtyToken"])}
                target="_blank"
              >
                Add on PulseX <Icon name="external-link-alt" size="xs" />
              </Link>
            </Flex>
            <Flex
              sx={{
                alignItems: "stretch",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
            <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer" }}
                  src={EARNicon}
                  variant="avatar"
                />
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={PLSXIcon}
                  variant="avatar"
                />
                Add EARN-PLSX Pair
              </Flex>
              <Link
                href={pulseXLink(addresses["earnToken"], addresses["plsxToken"])}
                target="_blank"
              >
                Add on PulseX <Icon name="external-link-alt" size="xs" />
              </Link>
            </Flex>
          </Box>
        </Box>
      </Card>

      <Card>
        <Heading>
          Metamask Features
          <Flex sx={{ justifyContent: "flex-end" }}>
            {/* <RemainingLQTY /> */}
          </Flex>
        </Heading>
        <Box sx={{ p: [2, 3] }}>
          <Paragraph sx={{}}>
            Use the below features to add the tokens used in FLEX Protocol to
            your MetaMask wallet. You will be able to see the balance by adding
            the tokens to your wallet.
          </Paragraph>

          <Box sx={{ p: [1, 2] }}>
            <Flex
              sx={{
                alignItems: "stretch",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={HEXicon}
                  variant="avatar"
                />
                Add HEX asset to wallet
              </Flex>
              <Flex
                sx={{ alignItems: "stretch", justifyContent: "space-between" }}
              >
                <Heading
                  sx={{ fontSize: 1, pr: [1, 2] }}
                  onClick={() => copyClipboard("HEX", addresses["plxToken"])}
                >
                  <Icon name="clipboard" size="2x" />
                </Heading>
                <Button
                  onClick={addTokenToWallet(
                    "HEX",
                    addresses["plxToken"],
                    HEXicon
                  )}
                  sx={{ height: "30px", width: "70px" }}
                >
                  Add
                </Button>
              </Flex>
            </Flex>

            <Flex
              sx={{
                alignItems: "stretch",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={HEXDCIcon}
                  variant="avatar"
                />
                Add HEXDC asset to wallet
              </Flex>
              <Flex
                sx={{ alignItems: "stretch", justifyContent: "space-between" }}
              >
                <Heading
                  sx={{ fontSize: 1, pr: [1, 2] }}
                  onClick={() => copyClipboard("HEXDC", addresses["lusdToken"])}
                >
                  <Icon name="clipboard" size="2x" />
                </Heading>
                <Button
                  onClick={addTokenToWallet(
                    "HEXDC",
                    addresses["lusdToken"],
                    HEXDCIcon
                  )}
                  sx={{ height: "30px", width: "70px" }}
                >
                  Add
                </Button>
              </Flex>
            </Flex>

            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                p: [1, 2],
              }}
            >
              <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
                <Image
                  sx={{ width: 20, height: 20, cursor: "pointer", mr: 1 }}
                  src={FLEXicon}
                  variant="avatar"
                />
                <span>Add FLEX asset to wallet</span>
              </Flex>
              <Flex
                sx={{ alignItems: "stretch", justifyContent: "space-between" }}
              >
                <Heading
                  sx={{ fontSize: 1, pr: [1, 2] }}
                  onClick={() => copyClipboard("FLEX", addresses["lqtyToken"])}
                >
                  <Icon name="clipboard" size="2x" />
                </Heading>
                <Button
                  onClick={addTokenToWallet(
                    "FLEX",
                    addresses["lqtyToken"],
                    FLEXicon
                  )}
                  sx={{ height: "30px", width: "70px" }}
                >
                  Add
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Card>
    </>
  );
};
