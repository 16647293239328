import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useTroveView } from "./context/TroveViewContext";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { ApproveAction } from "./TroveAction";
import { Icon } from "../Icon";

const selector = (state: LiquityStoreState) => {
  const { accountBalance, plsxTokenAllowance } = state;
  return {
    accountBalance,
    plsxTokenAllowance,
  };
};

export const NoTrove: React.FC = (props) => {
  const { dispatchEvent } = useTroveView();

  const { plsxTokenAllowance } = useLiquitySelector(selector);
  const plsxAllowance = Number(plsxTokenAllowance) / 1e8;

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("OPEN_TROVE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        <div>
          <Icon style={{ marginRight: 5, color: "#fb05f1" }} name="lock" />{" "}
          Vault
        </div>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="You haven't borrowed any HEXDC yet.">
          You can borrow HEXDC by opening a Vault.{" "}
          {plsxAllowance > 0 ? "" : "Please approve HEX Token to open Vault"}
        </InfoMessage>

        <Flex variant="layout.actions">
          {plsxAllowance > 0 ? (
            <Button onClick={handleOpenTrove}>Open Vault</Button>
          ) : (
            <ApproveAction transactionId={"hex-approval"}>
              Approve
            </ApproveAction>
          )}
        </Flex>
      </Box>
    </Card>
  );
};
