import { useEffect, useReducer, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { Button, Text, Flex, Link, Box, Grid, Card, Heading } from "theme-ui";
import { getTokensPrice } from "../utils/tokensPrice";

import { injectedConnector } from "../connectors/injectedConnector";
import { useAuthorizedConnection } from "../hooks/useAuthorizedConnection";

import { RetryDialog } from "./RetryDialog";
import { ConnectionConfirmationDialog } from "./ConnectionConfirmationDialog";
import { MetaMaskIcon } from "./MetaMaskIcon";
import { Icon } from "./Icon";
import { Modal } from "./Modal";
import { Footer } from "./Footer";
import { SystemStatsDisconnected } from "./SystemStatsDisconnected";

interface MaybeHasMetaMask {
  ethereum?: {
    isMetaMask?: boolean;
  };
}

type ConnectionState =
  | { type: "inactive" }
  | {
      type:
        | "activating"
        | "active"
        | "rejectedByUser"
        | "alreadyPending"
        | "failed";
      connector: AbstractConnector;
    };

type ConnectionAction =
  | { type: "startActivating"; connector: AbstractConnector }
  | { type: "fail"; error: Error }
  | { type: "finishActivating" | "retry" | "cancel" | "deactivate" };

const connectionReducer: React.Reducer<ConnectionState, ConnectionAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "startActivating":
      return {
        type: "activating",
        connector: action.connector,
      };
    case "finishActivating":
      return {
        type: "active",
        connector:
          state.type === "inactive" ? injectedConnector : state.connector,
      };
    case "fail":
      if (state.type !== "inactive") {
        return {
          type: action.error.message.match(/user rejected/i)
            ? "rejectedByUser"
            : action.error.message.match(/already pending/i)
            ? "alreadyPending"
            : "failed",
          connector: state.connector,
        };
      }
      break;
    case "retry":
      if (state.type !== "inactive") {
        return {
          type: "activating",
          connector: state.connector,
        };
      }
      break;
    case "cancel":
      return {
        type: "inactive",
      };
    case "deactivate":
      return {
        type: "inactive",
      };
  }

  console.warn("Ignoring connectionReducer action:");
  console.log(action);
  console.log("  in state:");
  console.log(state);

  return state;
};

const detectMetaMask = () =>
  (window as MaybeHasMetaMask).ethereum?.isMetaMask ?? false;

type WalletConnectorProps = {
  loader?: React.ReactNode;
};

export const WalletConnector: React.FC<WalletConnectorProps> = ({
  children,
  loader,
}) => {
  const { activate, deactivate, active, error } = useWeb3React<unknown>();
  const triedAuthorizedConnection = useAuthorizedConnection();
  const [connectionState, dispatch] = useReducer(connectionReducer, {
    type: "inactive",
  });
  const isMetaMask = detectMetaMask();

  useEffect(() => {
    if (error) {
      dispatch({ type: "fail", error });
      deactivate();
    }
  }, [error, deactivate]);

  useEffect(() => {
    if (active) {
      dispatch({ type: "finishActivating" });
    } else {
      dispatch({ type: "deactivate" });
    }
  }, [active]);

  if (!triedAuthorizedConnection) {
    return <>{loader}</>;
  }

  if (connectionState.type === "active") {
    return <>{children}</>;
  }

  return (
    <>
      <Box className="disconnectedBox">
        <Grid className="grid-disconnected" sx={{ mt: 30 }}>
          <Card className="card-left" variant="disconnected">
            <Heading as="h3">Welcome to FLEX</Heading>

            <p>
              Flex Protocol is live on PulseChain. Flex is a decentralized
              borrowing protocol that allows you to draw interest-free loans
              against $HEX used as collateral. Loans are paid out in $HEXDC (a
              USD pegged stablecoin) and need to maintain a minimum collateral
              ratio of 110%. In addition to the collateral, the loans are
              secured by a Stability Pool containing $HEXDC and by other
              borrowers collectively acting as guarantors of last resort. Learn
              more about these mechanisms in our documentation.
            </p>
            <div className="flexbox">
              <img className="ad" src="FLEX-Connect.jpg" />
              <p>
                Flex as a protocol is non-custodial, immutable, and
                governance-free. If you still have questions after browsing this
                documentation, please join our{" "}
                <a
                  className="link"
                  href="https://t.me/POWERCITYio/"
                  target="_blank"
                >
                  {" "}
                  Telegram
                </a>
                . Flex Protocol is a part of the POWERCITY Ecosystem. Learn more
                about the ecosystem and its other protocols here.
                <br />
                <br />
                <a
                  className="link"
                  href="https://docs.powercity.io/flex-protocol/"
                  target="_blank"
                >
                  Click here to read more on GitBook
                </a>
              </p>
            </div>
          </Card>
          <Card className="card-right" variant="disconnected">
            <SystemStatsDisconnected />
            <Button
              sx={{
                width: "100%",
                margin: "30px 0 10px 0",
              }}
              onClick={() => {
                dispatch({
                  type: "startActivating",
                  connector: injectedConnector,
                });
                activate(injectedConnector);
              }}
            >
              {isMetaMask ? (
                <>
                  <MetaMaskIcon />
                  <Box sx={{ ml: 2 }}>Connect to MetaMask</Box>
                </>
              ) : (
                <>
                  <Icon name="plug" size="lg" />
                  <Box sx={{ ml: 2 }}>Connect wallet</Box>
                </>
              )}
            </Button>
          </Card>
        </Grid>
      </Box>

      {connectionState.type === "failed" && (
        <Modal>
          <RetryDialog
            title={
              isMetaMask
                ? "Failed to connect to MetaMask"
                : "Failed to connect wallet"
            }
            onCancel={() => dispatch({ type: "cancel" })}
            onRetry={() => {
              dispatch({ type: "retry" });
              activate(connectionState.connector);
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              You might need to install MetaMask or use a different browser.
            </Box>
            <Link
              sx={{ lineHeight: 3 }}
              href="https://metamask.io/download.html"
              target="_blank"
            >
              Learn more <Icon size="xs" name="external-link-alt" />
            </Link>
          </RetryDialog>
        </Modal>
      )}

      {connectionState.type === "activating" && (
        <Modal>
          <ConnectionConfirmationDialog
            title={
              isMetaMask
                ? "Confirm connection in MetaMask"
                : "Confirm connection in your wallet"
            }
            icon={
              isMetaMask ? <MetaMaskIcon /> : <Icon name="wallet" size="lg" />
            }
            onCancel={() => dispatch({ type: "cancel" })}
          >
            <Text sx={{ textAlign: "center" }}>
              Confirm the request that&apos;s just appeared.
              {isMetaMask ? (
                <>
                  {" "}
                  If you can&apos;t see a request, open your MetaMask extension
                  via your browser.
                </>
              ) : (
                <>
                  {" "}
                  If you can&apos;t see a request, you might have to open your
                  wallet.
                </>
              )}
            </Text>
          </ConnectionConfirmationDialog>
        </Modal>
      )}

      {connectionState.type === "rejectedByUser" && (
        <Modal>
          <RetryDialog
            title="Cancel connection?"
            onCancel={() => dispatch({ type: "cancel" })}
            onRetry={() => {
              dispatch({ type: "retry" });
              activate(connectionState.connector);
            }}
          >
            <Text>
              To use FLEX Protocol, you need to connect your PulseChain account.
            </Text>
          </RetryDialog>
        </Modal>
      )}

      {connectionState.type === "alreadyPending" && (
        <Modal>
          <RetryDialog
            title="Connection already requested"
            onCancel={() => dispatch({ type: "cancel" })}
            onRetry={() => {
              dispatch({ type: "retry" });
              activate(connectionState.connector);
            }}
          >
            <Text>
              Please check your wallet and accept the connection request before
              retrying.
            </Text>
          </RetryDialog>
        </Modal>
      )}

      <Footer />
    </>
  );
};
