import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Card, Heading, Box, Text, Image, Grid, Link, Spinner } from "theme-ui";
import { Statistic } from "./Statistic";
import * as l from "../lexicon";
import { TokenPrice } from "./TokenPrice";
import { Icon } from "./Icon";
import HEXicon from "../assets/icons/hex.svg";
import FLEXicon from "../assets/icons/flex.svg";
import HEXDCIcon from "../assets/icons/hexdc.svg";
import abi from "./Disconnected/abi";

import { getTokensPrice } from "../utils/tokensPrice";
import { getEarnPrice } from "../utils/tokensPrice";

type SystemStatsDisconnectedProps = {
  variant?: string;
};

export const SystemStatsDisconnected: React.FC<SystemStatsDisconnectedProps> = ({}) => {
  const provider = new ethers.providers.JsonRpcProvider(
    "https://rpc-pulsechain.g4mm4.io" // #### NOTE! ####, change to mainnet url when launch.
  );

  // #### NOTE! ####, change to mainnet url when launch.
  const testnet =
    "https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/";
  const pulsexapp =
    "https://app.piteas.io/#/swap?inputCurrency=PLS&outputCurrency=";

  // add token to wallet
  const addTokenToWallet = (
    tokenSymbol: string,
    tokenAddress: string,
    tokenImage: string
  ) => async () => {
    if (window.ethereum) {
      try {
        const { ethereum } = window as any;
        let url = "";

        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          url = `http://localhost:3000/`;
        } else {
          url = `https://testnet.d2iqdsacwkdtdq.amplifyapp.com/`; // #### NOTE! ####, change to mainnet url when launch.
        }

        await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: 8,
              image: url + tokenImage,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // #### NOTE! ####, change to mainnet url when launch.
  const hexaddr = "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39";
  const hexdcToken = "0x1FE0319440A672526916C232EAEe4808254Bdb00";
  const flexaddr = "0x9c6fA17D92898B684676993828143596894AA2A6";

  const flexStaking = "0xCeC2c718ceFdb3A515D3CC22e430b46933922CE4";
  const troveManager = "0xC2D0720721d48cE85e20Dc9E01B8449D7eDd14CE";
  const activepools = "0xE4FD443B26fA809A48EB658eA7edeB31E95c2144";
  const defaultpool = "0xC1C8A7F32E9D5142A77A05F8969B28B58fA10F63";
  const stabilitypool = "0x271F576fD6DE465231320a0F9997ACb0C8b97e07";

  // const wplsaddr = "0x70499adEBB11Efd915E3b69E700c331778628707";
  // const usdaddr = "0x826e4e896CC2f5B371Cd7Bb0bd929DB3e3DB67c0";
  // const wplsContract = new ethers.Contract(wplsaddr, abi.combined, provider);
  // const usdContract = new ethers.Contract(usdaddr, abi.combined, provider);

  const hexContract = new ethers.Contract(hexaddr, abi.combined, provider);
  const flexStakingContract = new ethers.Contract(
    flexStaking,
    abi.combined,
    provider
  );
  const troveManagerContract = new ethers.Contract(
    troveManager,
    abi.combined,
    provider
  );
  const hexdcTokenContract = new ethers.Contract(
    hexdcToken,
    abi.combined,
    provider
  );

  const [loading, setLoading] = useState(true);
  const [flexStakeAmount, setFlexStakeAmount] = useState<any>("0");
  const [vaults, setVaults] = useState<any>("0");
  const [borrowRate, setBorrowRate] = useState<any>("0");
  const [tvl, setTvl] = useState<any>("0");
  const [tvlUsd, setTvlUsd] = useState<any>("0");
  // const [hexUsd, setHexUsd] = useState<any>("0");
  const [mintedHexdc, setMintedHexdc] = useState<any>("0");
  const [poolHexdc, setPoolHexdc] = useState<any>("0");
  const [
    hexdcInStabilityPoolPct,
    sethexdcInStabilityPoolPct,
  ] = useState<number>(0);
  const [totalCollPct, setTotalCollPct] = useState<number>(0);

  // shorten function
  const shorten = (number: any, decimalPlaces: number) => {
    const roundedNumber = Math.ceil(number * 10) / 10; // Round up to one decimal place
    const suffixes = ["", "K", "M", "B", "T"];
    const magnitude = Math.floor(Math.log10(number) / 3);
    const formattedNumber = (
      roundedNumber / Math.pow(10, magnitude * 3)
    ).toFixed(decimalPlaces); // Fixed to one decimal place
    return formattedNumber + suffixes[magnitude];
  };

  // #### NOTE! ####, change to FLEX ORACLE when launch.
  const [tokensPrice, setTokensPrice] = useState({
    FLEX: 0.0,
    HEXDC: 0.0,
    HEX: 0.0,
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getEarnPrice();
      setTokensPrice(response);
      localStorage.setItem("tokensPrice", JSON.stringify(response));

      // Continue with the rest of the logic using tokenPrice.HEX
      // Ensure tokenPrice.HEX is defined before proceeding
      if (response.HEX) {
        // tvl (active & default)
        const totalValueLockedActive = await hexContract.balanceOf(activepools);
        const totalValueLockedDefault = await hexContract.balanceOf(
          defaultpool
        );
        const tvlactive = ethers.utils.formatUnits(
          totalValueLockedActive.toString(),
          8
        );
        const tvldefault = ethers.utils.formatUnits(
          totalValueLockedDefault.toString(),
          8
        );
        const totalValueLocked = Number(tvlactive) + Number(tvldefault);

        setTvl(shorten(totalValueLocked, 1));

        // tvl usd
        setTvlUsd(shorten(Number(totalValueLocked) * Number(response.HEX), 1));

        // total staked
        const totalFLEXStaked = await flexStakingContract.totalLQTYStaked();
        setFlexStakeAmount(
          shorten(ethers.utils.formatUnits(totalFLEXStaked.toString(), 8), 1)
        );

        // total minted
        const totalHEXDCissued = await hexdcTokenContract.totalSupply();
        setMintedHexdc(
          shorten(ethers.utils.formatUnits(totalHEXDCissued.toString(), 8), 2)
        );

        // total in pool
        const totalHEXDCinpool = await hexdcTokenContract.balanceOf(
          stabilitypool
        );
        setPoolHexdc(
          shorten(ethers.utils.formatUnits(totalHEXDCinpool.toString(), 8), 2)
        );

        // total debt
        const totalSystemdebt = await troveManagerContract.getEntireSystemDebt();

        // total system collateral
        const totalCollateral = await troveManagerContract.getEntireSystemColl();

        // HEXDC In StabilityPool %
        sethexdcInStabilityPoolPct((totalHEXDCinpool / totalSystemdebt) * 100);

        // total system collateral %
        setTotalCollPct(
          ((totalCollateral * Number(response.HEX || 0)) / totalSystemdebt) *
            100
        );

        // tvl
        setTvl(shorten(totalValueLocked, 1));

        //total vaults
        const totalVaults = await troveManagerContract.getTroveOwnersCount();
        setVaults(totalVaults.toString());

        //get Borrowing Rate
        const borrowingRate = await troveManagerContract.getBorrowingRate();
        const percentage = (borrowingRate / 1000000).toFixed(2) + "%";
        setBorrowRate(percentage);

        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Heading>FLEX Statistics</Heading>

      {loading ? (
        <>
          <p>Loading...</p> <Spinner size="28px" sx={{ color: "text" }} />
        </>
      ) : (
        <>
          <Grid sx={{ mt: 30 }} gap={4} columns={[3]}>
            <Box sx={{ textAlign: "center" }}>
              <Image
                sx={{ width: 40, height: 40, cursor: "pointer" }}
                src={HEXicon}
                variant="avatar"
                onClick={addTokenToWallet("HEX", hexaddr, HEXicon)}
              />
              <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
                HEX
              </Heading>
              <Text sx={{ fontSize: 1, color: "#fb05f1" }}>
                ${tokensPrice.HEX}
              </Text>
              <Text
                sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}
              >
                <TokenPrice lexicon={l.OPEARN} />
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="info-circle" />
                </Link>
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="satellite" />
                </Link>
              </Text>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Image
                sx={{ width: 40, height: 40, cursor: "pointer" }}
                src={FLEXicon}
                variant="avatar"
                onClick={addTokenToWallet("FLEX", flexaddr, FLEXicon)}
              />
              <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
                FLEX
              </Heading>
              <Text sx={{ fontSize: 1, color: "#fb05f1" }}>
                ${tokensPrice.FLEX}
              </Text>
              <Text
                sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}
              >
                <TokenPrice lexicon={l.MPEARN} />
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="info-circle" />
                </Link>
                <Link
                  target="_blank"
                  href={testnet + "address/" + flexaddr}
                  sx={{ mr: 1 }}
                >
                  <Icon name="chart-bar" />
                </Link>
                <Link target="_blank" href={pulsexapp + flexaddr}>
                  <Icon name="exchange-alt" />
                </Link>
              </Text>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Image
                sx={{ width: 40, height: 40, cursor: "pointer" }}
                src={HEXDCIcon}
                variant="avatar"
                onClick={addTokenToWallet("HEXDC", hexdcToken, HEXDCIcon)}
              />
              <Heading as="h3" sx={{ mt: 0, mb: 0, fontWeight: "body" }}>
                HEXDC
              </Heading>
              <Text sx={{ fontSize: 1, color: "#fb05f1" }}>
                ${tokensPrice.HEXDC}
              </Text>
              <Text
                sx={{ fontSize: 1, display: "block", mb: 15, fontWeight: 200 }}
              >
                <TokenPrice lexicon={l.MPPXDC} />
                <Link href="#" sx={{ mr: 1 }}>
                  <Icon name="info-circle" />
                </Link>
                <Link
                  target="_blank"
                  href={testnet + "address/" + hexdcToken}
                  sx={{ mr: 1 }}
                >
                  <Icon name="chart-bar" />
                </Link>
                <Link target="_blank" href={pulsexapp + hexdcToken}>
                  <Icon name="exchange-alt" />
                </Link>
              </Text>
            </Box>
          </Grid>

          <Heading as="h2" sx={{ mt: 3, fontWeight: "body" }}>
            Protocol
          </Heading>

          <Statistic lexicon={l.BORROW_FEE}>{borrowRate}</Statistic>

          <Statistic lexicon={l.TVL}>
            {tvl} <Text sx={{ fontSize: 1 }}>&nbsp;HEX</Text>
            <Text sx={{ fontSize: 1 }}>&nbsp;(${tvlUsd})</Text>
          </Statistic>
          <Statistic lexicon={l.TROVES}>{vaults}</Statistic>
          <Statistic lexicon={l.LUSD_SUPPLY}>{mintedHexdc}</Statistic>

          <Statistic lexicon={l.STABILITY_POOL_LUSD}>
            {poolHexdc == "NaNundefined" ? "0" : poolHexdc}
            <Text sx={{ fontSize: 1 }}>
              &nbsp;({hexdcInStabilityPoolPct.toFixed(1)}%)
            </Text>
          </Statistic>

          <Statistic lexicon={l.STAKED_LQTY}>
            {flexStakeAmount == "NaNundefined" ? "0" : flexStakeAmount}
          </Statistic>
          <Statistic lexicon={l.TCR}>{totalCollPct.toFixed(1)}%</Statistic>
        </>
      )}
    </>
  );
};
